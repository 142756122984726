<template>
    <aside class="aside">
        <el-menu
            ref="mainMenu"
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            unique-opened
            :collapse="baseIsCollapsed"
            menu-trigger="click"
            active-text-color="#484BAF"
            text-color="#333333"
        >
            <el-menu-item
                index="dashboard"
                :class="{'is-active': route.name === 'dashboard'}"
                @click="router.push({name: 'dashboard'})"
            >
                <i class="el-menu-item__icon">
                    <inline-svg
                        class="el-menu-item__icon-svg"
                        src="/images/svg-icons/dashboard-icon.svg"
                    />
                </i>
                <span class="el-menu-item__name">Dashboard</span>
            </el-menu-item>
            <companies-sub-menu
                v-if="auth.check('retailer_companies.view')"
                @openMenu="openMenu"
            />
            <el-menu-item
                v-if="auth.check('coaches.view')"
                :class="{'is-active': route.name === 'coaches'}"
                index="coaches"
                class="el-sub-menu--coaches"
                @click="router.push({name: 'coaches'})"
            >
                <template #title>
                    <i class="el-menu-item__icon fa fa-chalkboard-user" />
                    <span class="el-menu-item__name">Coaches</span>
                </template>
            </el-menu-item>
            <categories-sub-menu
                v-if="auth.check('retailer_categories.view')"
                @openMenu="openMenu"
            />
            <div class="flex-grow" />
            <div class="el-menu__item">
                <div
                    class="el-menu__collapse-btn"
                    :class="{'el-menu__collapse-btn--close' : baseIsCollapsed }"
                    @click.prevent="collapse"
                >
                    <i class="el-menu__collapse-btn-icon">
                        <inline-svg
                            class="el-menu__collapse-btn-icon-svg"
                            src="/images/svg-icons/arrow-right-icon.svg"
                        />
                    </i>
                </div>
            </div>
        </el-menu>
    </aside>
</template>

<script setup>

import store from '~/store'
import {ref, computed, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useAuth} from '@websanova/vue-auth'
import CategoriesSubMenu from './CategoriesSubMenu'
import CompaniesSubMenu from './CompaniesSubMenu'
import InlineSvg from 'vue-inline-svg'

const route = useRoute()
const router = useRouter()
const auth = useAuth()

const baseIsCollapsed = computed(() => store.getters['base/baseIsCollapsed'])

const mainMenu = ref(null)

function collapse() {
    store.commit('base/toggleCollapse')
}

function openMenu(index) {
    mainMenu.value.open(index)
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
aside {
    $local-light-gray: #dddddd;
    padding: 30px 0 20px 10px;
    @media all and (min-width: $s) {
        padding: 40px 0 20px 15px;
    }
    @media all and (min-width: $m) {
        padding: 40px 0 20px 20px;
    }
    .el-menu {
        display: flex;
        flex-direction: column;
        width: 220px;
        height: 100%;
        border-radius: 4px;
        border: 1px solid $local-light-gray;
        padding: 15px 0;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
        overflow-y: auto;
        overflow-x: hidden;
        user-select: none;
        @media all and (min-width: $xs) {
            width: 250px;
        }
        @media all and (min-width: $m) {
            width: 300px;
        }
        &__item {
            padding-left: 20px;
        }
        &__collapse-btn {
            position: relative;
            display: flex;
            align-items: center;
            width: 30px;
            height: 32px;
            cursor: pointer;
            &:hover {
                .el-menu__collapse-btn-icon-svg {
                    fill: $green;
                }
            }
            &-icon {
                width: 16px;
                height: 16px;
                transform: rotate(180deg);
                transition: 0.25s rotate ease-in-out;
                &-svg {
                    max-width: 100%;
                    max-height: 100%;
                    fill: $dark-middle;
                    transition: 0.25s fill ease-in-out;
                }
            }
        }
        &--collapse {
            width: 60px;
            .el-menu__collapse-btn-icon {
                transform: rotate(0);
            }
        }
    }
}
</style>
