<template>
    <el-sub-menu
        index="products"
        class="el-sub-menu--products"
        popper-class="el-sub-menu--popper"
        @click="goToCategories"
    >
        <template #title>
            <i class="el-menu-item__icon">
                <inline-svg
                    class="el-menu-item__icon-svg"
                    src="/images/svg-icons/products-icon.svg"
                />
            </i>
            <span class="el-menu-item__name">Products</span>
        </template>
        <el-icon
            v-if="!categories"
            class="is-loading"
            style="width: 100%"
        >
            <Loading />
        </el-icon>
        <el-sub-menu
            v-for="category1 in categories"
            :key="category1._id"
            :index="category1.slug"
            popper-class="el-sub-menu__level-1"
            @click.stop="open1(category1.slug)"
        >
            <template #title>
                <span class="el-sub-menu__title-name">
                    {{ category1.name }}
                </span>
            </template>
            <template v-if="activeCat1 === category1.slug">
                <el-icon
                    v-if="!subCat1"
                    class="is-loading"
                    style="width: 100%"
                >
                    <Loading />
                </el-icon>
                <el-sub-menu
                    v-for="category2 in subCat1"
                    :key="category2._id"
                    :index="category2.slug"
                    popper-class="el-sub-menu__level-2"
                    @click.stop="open2(category2.slug)"
                >
                    <template #title>
                        <span class="el-sub-menu__title-name">
                            {{ category2.name }}
                        </span>
                    </template>
                    <template v-if="activeCat2 === category2.slug">
                        <el-icon
                            v-if="!subCat2"
                            class="is-loading"
                            style="width: 100%"
                        >
                            <Loading />
                        </el-icon>
                        <el-sub-menu
                            v-for="category3 in subCat2"
                            :key="category3._id"
                            :index="category3.slug"
                            popper-class="el-sub-menu__level-3"
                            @click.stop="open3(category3.slug)"
                        >
                            <template #title>
                                <span class="el-sub-menu__title-name">
                                    {{ category3.name }}
                                </span>
                            </template>
                            <template v-if="activeCat3 === category3.slug">
                                <el-icon
                                    v-if="!subCat3"
                                    class="is-loading"
                                    style="width: 100%"
                                >
                                    <Loading />
                                </el-icon>
                                <el-sub-menu
                                    v-for="category4 in subCat3"
                                    :key="category4._id"
                                    :index="category4.slug"
                                    popper-class="el-sub-menu__level-4"
                                    @click.stop="open4(category4.slug)"
                                >
                                    <template #title>
                                        <span class="el-sub-menu__title-name">
                                            {{ category4.name }}
                                        </span>
                                    </template>
                                </el-sub-menu>
                            </template>
                        </el-sub-menu>
                    </template>
                </el-sub-menu>
            </template>
        </el-sub-menu>
    </el-sub-menu>
</template>

<script setup>
import {useRoute, useRouter} from 'vue-router'
import {useAuth} from '@websanova/vue-auth'
import {computed, onMounted, ref} from 'vue'
import categoryApi from '~/modules/category/cateogryApi'
import bus from '~/includes/Event'
import store from '~/store'
import InlineSvg from 'vue-inline-svg'

const route = useRoute()
const router = useRouter()
const auth = useAuth()
const emit = defineEmits()

const baseIsCollapsed = computed(() => store.getters['base/baseIsCollapsed'])

const categories = ref(null)

onMounted(() => {
    if (!baseIsCollapsed.value) loadTree()
    bus.$on('open-sub-category', openSubCategory)
})

async function goToCategories() {
    await router.push({name: 'category'})
    await loadTree()
}

async function loadTree() {
    if (route.name !== 'category') return
    emit('openMenu', 'products')
    const {data} = await categoryApi.fetchByParents()
    categories.value = data

    const route_params = _.clone(route.params)
    if (route_params.department) await open1(route_params.department, false)
    if (route_params.super_cat) await open2(route_params.super_cat, false)
    if (route_params.cat) await open3(route_params.cat, false)
    if (route_params.sub_cat) await open4(route_params.sub_cat, false)
    if (route_params.segment) await open5(route_params.segment, false)
}

const activeCat1 = ref(null)
const activeCat2 = ref(null)
const activeCat3 = ref(null)
const activeCat4 = ref(null)
const activeCat5 = ref(null)

const subCat1 = ref(null)
const subCat2 = ref(null)
const subCat3 = ref(null)
const subCat4 = ref(null)

async function open1(category_slug, push = true) {
    subCat1.value = null
    activeCat1.value = category_slug
    activeCat2.value = null
    activeCat3.value = null
    activeCat4.value = null
    activeCat5.value = null

    const {data} = await categoryApi.fetchByParents(categoryChain.value)
    subCat1.value = data
    reloadData(category_slug, push)
}

async function open2(category_slug, push = true) {
    subCat2.value = null
    activeCat2.value = category_slug
    activeCat3.value = null
    activeCat4.value = null
    activeCat5.value = null

    const {data} = await categoryApi.fetchByParents(categoryChain.value)
    subCat2.value = data
    reloadData(category_slug, push)
}

async function open3(category_slug, push = true) {
    subCat3.value = null
    activeCat3.value = category_slug
    activeCat4.value = null
    activeCat5.value = null

    const {data} = await categoryApi.fetchByParents(categoryChain.value)
    subCat3.value = data
    reloadData(category_slug, push)
}

async function open4(category_slug, push = true) {
    subCat4.value = null
    activeCat4.value = category_slug
    reloadData(category_slug, push)
}

function open5(category_slug, push = true) {
    // TODO figure out what to show
    // activeCat5.value = category_slug
    // reloadData(category_slug, push)
}

const categoryChain = computed(() =>
    [activeCat1.value, activeCat2.value, activeCat3.value, activeCat4.value, activeCat5.value].filter(Boolean))

function reloadData(category_slug, push = true) {
    emit('openMenu', category_slug)

    const path = '/category/' + categoryChain.value.join('/')

    if (route.path !== path && push) router.push(path)
}

function openSubCategory(path) {
    if (baseIsCollapsed.value) return
    const path_array = path.split('/').filter(Boolean).slice(1)
    const slug = _.last(path_array)
    switch (path_array.length) {
    case 1: open1(slug)
        break
    case 2: open2(slug)
        break
    case 3: open3(slug)
        break
    case 4: open4(slug)
        break
    }
}

</script>
