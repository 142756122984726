import axios from 'axios'

export default {
    fetchCompanies() {
        return axios.get(window.config.ssURL + 'companies')
    },
    fetchDashboardProducts(company_id = '', data) {
        return axios.get(window.config.ssURL + 'dashboards/dashboard-products/' + company_id, { params: data })
    },
    fetchClientLevels() {
        return axios.get(window.config.ssURL + 'client-levels')
    },
    getRootClientLevels() {
        return axios.get(window.config.ssURL + 'client-levels/root')
    }
}
