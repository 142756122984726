<template>
    <el-sub-menu
        v-for="level in levels"
        :key="level._id"
        :index="level._id"
        @click.stop="goToClientLevel(level._id)"
    >
        <template #title>
            <span class="el-sub-menu__title-name">
                {{ level.name }}
            </span>
        </template>
        <template v-if="level.children && level.children.length > 0">
            <client-levels-sub-menu
                :levels="level.children"
            />
        </template>
    </el-sub-menu>
</template>

<script setup>
import ClientLevelsSubMenu from "~/base/components/sidebar/ClientLevelsSubMenu.vue";
import {useRouter} from "vue-router";

const router = useRouter()
const props = defineProps({
    levels: {
        type: Array,
        default: {}
    },
});

function goToClientLevel(id) {
    router.push({name: 'client-levels.show', params: {id: id}})
}


</script>
