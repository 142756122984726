import app from '~/app'

export class AuthResolver {
    static authRedirect(to, from, next) {
        if (!app.config.globalProperties.$auth.check()) {
            next({path: '/login'})
        } else {
            next()
        }
    }
}
