<template>
    <div class="header-controls">
        <div
            v-for="item in controlsList"
            :key="item.title"
            class="header-controls__item"
        >
            <template v-if="item.title === 'Log Out'">
                <div
                    class="header-controls__link"
                    @click="logout"
                >
                    <span class="header-controls__link-icon">
                        <inline-svg
                            class="header-controls__link-icon-svg"
                            :src="item.icon"
                        />
                    </span>
                    <span class="header-controls__link-title">
                        {{ item.title }}
                    </span>
                </div>
            </template>
            <template v-else>
                <a
                    :href="item.link"
                    class="header-controls__link"
                >
                    <span class="header-controls__link-icon">
                        <inline-svg
                            class="header-controls__link-icon-svg"
                            :src="item.icon"
                        />
                    </span>
                    <span class="header-controls__link-title">
                        {{ item.title }}
                    </span>
                </a>
            </template>
        </div>
    </div>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import {ElMessageBox} from 'element-plus'
import bus from '~/includes/Event'
import store from '~/store'
import {useRouter} from 'vue-router'
import {useAuth} from '@websanova/vue-auth'

const router = useRouter()
const auth = useAuth()
const user = auth.user()

const props = defineProps({
    controlsList: {
        type: Array,
        default: () => {return []}
    }
})

function logout() {
    ElMessageBox.confirm('Confirm LogOut', 'You will be logged out', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
    }).then(async () => {
        bus.$emit('logout')
        await auth.logout()
    })
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.header-controls {
    $block-name: &;
    display: flex;
    &__item {
        &:not(:last-child) {
            margin-right: 15px;
            @media all and (max-width: $xs - 1) {
                margin-right: 10px;
            }
            @media all and (min-width: $s){
                margin-right: 40px;
            }
        }
    }
    &__link {
        display: flex;
        align-items: flex-start;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            #{$block-name} {
                &__link {
                    &-title {
                        border-bottom-color: $indigo;
                    }
                }
            }
        }
        &-icon {
            display: block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            @media all and (min-width: $xs){
                margin-right: 10px;
            }
            &-svg {
                fill: $indigo;
            }
        }
        &-title {
            border-bottom: 2px solid transparent;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.4;
            color: $indigo;
            transition: 0.25s border-bottom-color ease-in-out;
        }
    }
}
</style>
