<template>
    <div class="el-main__content-top-filters">
        <div class="el-main__content-date-picker">
            <el-date-picker
                v-model="filters.dateRange"
                type="daterange"
                range-separator="–"
                start-placeholder="Start date"
                end-placeholder="End date"
                class="el-date-picker el-date-picker--no-grow"
                popper-class="el-popper--date-picker-period"
                :disabled-date="disabledDate"
                format="MM/DD/YYYY"
            />
        </div>
    </div>
</template>

<script setup>
import { useModelWrapper } from '~/includes/modelWrapper'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'modelValue')

function disabledDate(time) {
    return time.getTime() > Date.now()
}
</script>
