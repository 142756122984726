<template>
    <el-form
        ref="forgotPasswordForm"
        :model="form"
        :rules="rules"
        class="auth-form"
        @submit.prevent="passwordReset"
    >
        <input
            type="hidden"
            name="token"
            :value="token"
        >
        <input
            type="hidden"
            name="email"
            :value="email"
        >
        <el-form-item
            class="el-form-item--default"
        >
            <div class="auth-form__title">
                Reset Password
            </div>
        </el-form-item>
        <el-form-item
            prop="password"
            class="el-form-item--default"
            :error="formErrors.get('password')"
        >
            <label class="el-form-item__label--floating-label">
                <el-input
                    v-model="form.password"
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder=" "
                    class="el-input--floating-label el-input--password"
                    autocomplete="new-password"
                >
                    <template #prefix>
                        <i
                            v-if="form.password.length > 0"
                            class="el-input__prefix-icon fa"
                            :class="{
                                'fa-eye-slash' : !showPassword,
                                'fa-eye' : showPassword,
                            }"
                            @click="showPassword = !showPassword"
                        />
                    </template>
                    <template #suffix>
                        Password
                    </template>
                </el-input>
            </label>
        </el-form-item>
        <el-form-item
            prop="password_confirmation"
            class="el-form-item--default"
            :error="formErrors.get('password_confirmation')"
        >
            <label class="el-form-item__label--floating-label">
                <el-input
                    v-model="form.password_confirmation"
                    name="password_confirmation"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    placeholder=" "
                    class="el-input--floating-label el-input--password"
                    autocomplete="new-password"
                >
                    <template #prefix>
                        <i
                            v-if="form.password_confirmation.length > 0"
                            class="el-input__prefix-icon fa"
                            :class="{
                                'fa-eye-slash' : !showConfirmPassword,
                                'fa-eye' : showConfirmPassword,
                            }"
                            @click="showConfirmPassword = !showConfirmPassword"
                        />
                    </template>
                    <template #suffix>
                        Confirm password
                    </template>
                </el-input>
            </label>
        </el-form-item>
        <el-form-item
            class="el-form-item--default"
        >
            <el-button
                :loading="loading"
                round
                type="primary"
                class="w-100"
                @click.prevent="passwordReset"
            >
                Reset Password
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import {Errors} from '~/includes/Errors'
import authApi from '~/modules/auth/authApi'
import validatePassword from '~/includes/mixins/validatePassword'

export default {
    name: 'PasswordResetForm',
    mixins: [ validatePassword ],
    props: {
        token: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        button: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            loading: false,
            form: {
                token: this.token,
                email: this.email,
                password: '',
                password_confirmation: ''
            },
            rules: {
                password: [
                    { required: true, message: 'Please enter your password', trigger: 'blur' },
                    { validator: this.checkPasswordComplicated, trigger: ['change', 'blur'] }
                ],
                password_confirmation : [
                    { required: true, message: 'Please confirm password', trigger: 'blur' },
                    { validator: this.checkPassIdentical, trigger: ['change', 'blur'] }
                ]
            },
            formErrors: new Errors(),
            showPassword: false,
            showConfirmPassword: false,
        }
    },
    methods: {
        passwordReset() {
            this.loading = true
            authApi.resetPassword(this.form).then(response => {
                // this.$message.success(response.data.message)

                this.$auth.login({
                    data: {
                        email: this.form.email,
                        password: this.form.password,
                        device_name: this.$config.deviceName
                    },
                    redirect: this.$router.resolve({ name: 'dashboard' }).href
                }).catch(error => {
                    if (error.response.data.errors) {
                        this.errors.record(error.response.data.errors)
                    }
                })
            }).catch(error => {
                if (error.response.data.errors) {
                    this.formErrors.record(error.response.data.errors)
                    if (error.response.data.errors.email) {
                        let link = ''
                        if (error.response.data.errors.email[0].split(' ').includes('token')) {
                            link = ' <a href="/forgot/password">Forgot password</a>'
                        }
                        this.$message({
                            type: 'error',
                            dangerouslyUseHTMLString: true,
                            duration: 30000,
                            message: error.response.data.errors.email[0] + link,
                        })
                    }
                }
            }).finally(() => this.loading = false)
        },
    },
}
</script>
