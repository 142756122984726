<template>
    <router-link
        :to="{ name: item.path }"
        class="profile-menu-link"
    >
        <div class="profile-menu-link__icon">
            <inline-svg
                class="profile-menu-link__icon-svg"
                :src="item.icon"
                fill="#757575"
            />
        </div>
        <div class="profile-menu-link__title default-text">
            {{ item.title }}
        </div>
    </router-link>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.profile-menu-link {
    $block-name: &;
    display: inline-flex;
    align-items: center;
    padding: 0 5px;
    text-decoration: none;
    &__title {
        transition: 0.2s color ease-in-out;
    }
    &__icon {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 20px;
        overflow: hidden;
        &-svg {
            max-width: 100%;
            max-height: 100%;
            transition: 0.2s fill ease-in-out;
        }
    }
    &:hover {
        #{$block-name} {
            &__title {
                color: $indigo;
            }
            &__icon-svg {
                fill: $indigo;
            }
        }
    }
}
</style>
