import NotFound     from '~/base/components/NotFound'
import Base         from '~/base/components/Base'
import {AuthResolver} from '~/includes/AuthResolver'

const requireContext = require.context('../modules', true, /routes\.js$/)

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )

let moduleRoutes = []

for(let i in modules) {
    moduleRoutes = moduleRoutes.concat(modules[i][1].default)
}

export const routes = [
    {
        path: '/',
        component: Base,
        meta: {auth: true},
        children: [
            {
                path: '',
                name: 'Home',
                redirect: 'dashboard',
                beforeEnter: AuthResolver.authRedirect
            },
            ...moduleRoutes,
            {
                path: ':pathMatch(.*)*',
                component: NotFound,
                name: '404',
                meta: {auth: undefined},
            }
        ],
    },
]

