<template>
    <div class="profile-menu">
        <div
            v-for="(link, index) in list"
            :key="index"
            class="profile-menu__item"
            :class="{'profile-menu__item--bordered': link.title === 'Sugar Substitutes'}"
        >
            <profile-menu-link :item="link" />
        </div>
    </div>
</template>

<script setup>
import ProfileMenuLink from './ProfileMenuLink'
import {useAuth} from '@websanova/vue-auth'

const auth = useAuth()

const name = 'ProfileMenu'
const user = auth.user()
const props = defineProps({
    list: {
        type: Array,
        required: true,
    },
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
$local-light-gray: #e0e0e0;
.profile-menu {
    $block-name: &;
    &__item {
        margin-bottom: 20px;
        line-height: 1;
        &:empty {
            display: none;
        }
        &--bordered,
        &:nth-child(2) {
            border-bottom: 1px solid $local-light-gray;
            padding-bottom: 20px;
        }
    }
}
</style>
