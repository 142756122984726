<template>
    <header class="header-default">
        <div class="g-container">
            <div class="header-default__inner">
                <div class="header-default__left-side">
                    <a
                        href="/"
                        class="header-default__logo"
                    >
                        <img
                            src="/images/logo.png"
                            alt=""
                            class="header-default__logo-i"
                        >
                    </a>
                </div>
                <div class="header-default__controls">
                    <header-controls :controls-list="controls" />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
import {ref} from 'vue'
import HeaderControls from '~/base/components/header-controls/HeaderControls'

const controls = ref([
    {
        title: 'Help',
        link: '/help',
        icon: '/images/svg-icons/life-ring-icon.svg'
    },
    {
        title: 'Log Out',
        icon: '/images/svg-icons/user-icon.svg'
    },
])
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.header-default {
    $block-name: &;
    position: relative;
    height: 54px;
    background-color: $white;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    @media all and (min-width: $xs){
        height: 70px;
    }
    .g-container {
        @media all and (min-width: $m) {
            max-width: 1920px;
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        @media all and (min-width: $xs){
            padding: 18px 0;
        }
    }
    &__left-side {
        display: flex;
        align-items: center;
    }
    &__logo {
        display: block;
        width: 150px;
        height: 30px;
        text-decoration: none;
        @media all and (min-width: $xs){
            width: 180px;
            height: 34px;
            margin-right: 30px;
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__controls {
        padding-top: 5px;
    }
}
</style>
