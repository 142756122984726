import axios from 'axios'

export default {

    login(data) {
        return axios.post('login', data)
    },

    forgotPassword(form) {
        return axios.post('password/email', form)
    },

    resetPassword(form) {
        return axios.post('password/reset', form)
    },

    verify(user, query) {
        return axios.post(`email/verify/${user}?${query}`)
    },

    resend() {
        return axios.post('email/resend')
    },
}
