import CompanyIndex from './components/CompanyIndex'

export default [
    {
        path: 'client-levels',
        name: 'client-levels',
        component: CompanyIndex,
        meta: {
            auth: 'retailer_companies.view'
        }
    },
    {
        path: 'client-levels/:id',
        name: 'client-levels.show',
        component: CompanyIndex,
        meta: {
            auth: 'retailer_companies.view'
        }
    },
]
