<template>
    <header-default />
    <el-container class="home-container">
        <sidebar />
        <el-container>
            <el-main>
                <child />
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Child from '~/base/components/Child'
import Sidebar from '~/base/components/sidebar/Sidebar'
import HeaderDefault from '~/base/components/header-default/HeaderDefault'
</script>
