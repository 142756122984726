<template>
    <div class="el-main__content-top">
        <filter-top v-model="filters" />
        <div class="el-main__content-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{name: 'dashboard'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Coaches
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        :row-class-name="tableRowClassName"
        border
        class="el-table--companies"
    >
        <el-table-column
            fixed
            prop="name"
            label=""
            min-width="170"
        />
    </el-table>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import coachApi from '../coachApi'
import FilterTop from './FilterTop'

const route = useRoute()

const tableData = ref(null)
const coachData = ref(null)
const loading = ref(true)

const filters = ref({
    dateRange: ''
})

onMounted(() => getData())

watch(() => filters.value, val => {
    getData()
},
{ deep: true }
)

async function getData() {
    loading.value = true


    loading.value = false
}

const tableRowClassName = ({row, rowIndex}) => {
    return row.className ?? ''
}
</script>
