<template>
    <div class="el-main__content-top">
        <filter-top v-model="filters" />
        <div class="el-main__content-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{name: 'dashboard'}">
                    ..
                </el-breadcrumb-item>
                <template v-if="clientLevel">
                    <el-breadcrumb-item :to="{name: 'companies'}">
                        Client Level
                    </el-breadcrumb-item>
                    <el-breadcrumb-item v-if="clientLevel">
                        {{ clientLevel.name }}
                    </el-breadcrumb-item>
                </template>
                <template v-else>
                    <el-breadcrumb-item>
                        Client Level
                    </el-breadcrumb-item>
                </template>
            </el-breadcrumb>
        </div>
    </div>
    <el-table
        v-loading="loading"
        :data="tableData"
        :row-class-name="tableRowClassName"
        border
        class="el-table--companies"
    >
        <el-table-column
            fixed
            prop="name"
            label=""
            min-width="170"
        />
        <el-table-column
            prop="invited"
            label="Invited"
            min-width="110"
        />
        <el-table-column
            prop="engaged"
            label="Engaged"
            min-width="110"
        />
        <el-table-column
            prop="engaged_percent"
            label="% Engaged"
            min-width="110"
        />
        <el-table-column
            prop="complete"
            label="Complete"
            min-width="110"
        />
        <el-table-column
            prop="complete_percent"
            label="% Complete"
            min-width="110"
        />
        <el-table-column
            prop="active"
            label="Active"
            min-width="110"
        />
        <el-table-column
            prop="active_percent"
            label="% Active"
            min-width="110"
        />
    </el-table>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import companyApi from '../companyApi'
import bus from '~/includes/Event'
import FilterTop from './FilterTop'

const route = useRoute()

const tableData = ref(null)
const clientLevel = ref(null)
const loading = ref(true)

const filters = ref({
    dateRange: ''
})

onMounted(() => getData())

watch(() => filters.value, val => {
    getData()
},
{ deep: true }
)

const FIELD_NAME = {
    replaced: '# Product Replaced',
    replaced_per_user: 'Units per user',
    rated_percent: 'Percentage Rated',

    thumb_up: 'Rated Thumb up',
    thumb_up_per_user: 'Units per user',
    thumb_up_percent: 'Percentage of Rated',

    thumb_down: 'Rated Thumb down',
    thumb_down_per_user: 'Units per user',
    thumb_down_percent: 'Percentage of Rated',
}

async function getData() {
    loading.value = true
    const level_id = route?.params?.id ?? ''
    const {data: {data, total_users, pmo_status, client_level}} = await companyApi.fetchDashboardProducts(level_id, filters.value)

    if (level_id) bus.$emit('open-company', level_id)
    clientLevel.value = client_level

    let tableD = []
    tableD[0] = {
        className: 'table-top-row',
        invited: total_users.invited,
        engaged: total_users.engaged,
        engaged_percent: (total_users.invited ? _.round(total_users.engaged / total_users.invited * 100) : 0) + '%',
        complete: total_users.complete,
        complete_percent: (total_users.invited ? _.round(total_users.complete / total_users.invited * 100) : 0) + '%',
        active: total_users.active,
        active_percent: (total_users.invited ? _.round(total_users.active / total_users.invited * 100) : 0) + '%',
    }
    tableD[1] = {
        className: 'pmo_status-row',
        name: 'PMO Status',
        engaged: pmo_status.engaged,
        complete: pmo_status.complete,
        active: pmo_status.active,
    }
    let i = 2
    _.forEach(data, (item, key) => {
        tableD[i] = {
            className: key + '-row',
            name: FIELD_NAME[key],
            ...item
        }
        i++
    })
    tableData.value = tableD
    loading.value = false
}

const tableRowClassName = ({row, rowIndex}) => {
    return row.className ?? ''
}
</script>
