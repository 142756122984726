import axios from 'axios'

const API_ENDPOINT = window.config.ssURL + 'dashboards-categories'

export default {
    fetchByParents(parents = []) {
        return axios.get(API_ENDPOINT + '/get-by-parents/' + parents.join('/'))
    },

    fetchStatisticsByParents(parents, data) {
        return axios.get(API_ENDPOINT + '/get-statistics-by-parents/' + parents.join('/'), {params: data})
    },

    fetchStores(search) {
        return axios.get(window.config.ssURL + 'stores/list/' + search)
    },
    fetchColumns() {
        return [
            {
                _id: 'count_replaced',
                name: 'Replaced',
                show: true,
            },
            {
                _id: 'replaced_percent',
                name: 'Replaced percent',
                show: true,
            },
            {
                _id: 'count_removed',
                name: 'Removed',
                show: true,
            },
            {
                _id: 'removed_percent',
                name: 'Removed percent',
                show: true,
            },
            {
                _id: 'count_reduced',
                name: 'Reduced',
                show: true,
            },
            {
                _id: 'reduced_percent',
                name: 'Reduced percent',
                show: true,
            },
            {
                _id: 'count_rated',
                name: 'Rated',
                show: true,
            },
            {
                _id: 'rated_percent',
                name: 'Rated percent',
                show: true,
            },
            {
                _id: 'count_thumb_up',
                name: 'Thumb Up',
                show: true,
            },
            {
                _id: 'thumb_up_percent',
                name: 'Thumb Up percent',
                show: true,
            },
            {
                _id: 'count_thumb_down',
                name: 'Thumb Down',
                show: true,
            },
            {
                _id: 'thumb_down_percent',
                name: 'Thumb Down percent',
                show: true,
            },
            {
                _id: 'total',
                name: 'Total',
                show: true,
            }
        ]
    }
}
