<template>
    <div class="el-main__content-columns-options">
        <div
            v-if="columns"
            class="el-main__content-filter"
        >
            <el-select
                v-model="columns"
                multiple
                collapse-tags
                class="el-select--filter el-select--filter-columns"
                placeholder="Edit Columns"
                popper-class="el-popper--columns"
            >
                <el-option
                    v-for="item in columnsOptions"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                />
            </el-select>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import { useModelWrapper } from '~/includes/modelWrapper'
import categoryApi from '../cateogryApi'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Array,
        required: true
    },
})

const columns = useModelWrapper(props, emit, 'modelValue')

const columnsOptions = ref([])

onMounted(() => loadColumnsData())

async function loadColumnsData() {
    columnsOptions.value = await categoryApi.fetchColumns()
}

</script>
