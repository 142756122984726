<template>
    <el-sub-menu
        index="companies"
        class="el-sub-menu--companies"
        popper-class="el-popper--companies"
        @click="goToLevels"
    >
        <template #title>
            <i class="el-menu-item__icon">
                <inline-svg
                    class="el-menu-item__icon-svg"
                    src="/images/svg-icons/companies-icon.svg"
                />
            </i>
            <span class="el-menu-item__name">Client levels</span>
        </template>
        <el-icon
            v-if="!levels"
            class="is-loading"
            style="width: 100%"
        >
            <Loading />
        </el-icon>
        <client-levels-sub-menu
            :levels="levels"
        />
    </el-sub-menu>
</template>

<script setup>

import {computed, onMounted, ref} from 'vue'
import companyApi from '~/modules/company/companyApi'
import bus from '~/includes/Event'
import store from '~/store'
import {useRoute, useRouter} from 'vue-router'
import InlineSvg from 'vue-inline-svg'
import ClientLevelsSubMenu from "~/base/components/sidebar/ClientLevelsSubMenu.vue";

const route = useRoute()
const router = useRouter()
const emit = defineEmits()

const baseIsCollapsed = computed(() => store.getters['base/baseIsCollapsed'])

const levels = ref(null)

onMounted(() => {
    if (!baseIsCollapsed.value) loadTree()
    bus.$on('open-sub-category', 1)
})

function buildHierarchy(objects, parentId = null) {
    const children = objects.filter(obj => obj.parent_id === parentId);
    if (children.length === 0) return null;
    return children.map(child => {
        const obj = {...child};
        const nestedChildren = buildHierarchy(objects, child._id);
        if (nestedChildren) obj.children = nestedChildren;
        return obj;
    });
}

async function loadTree() {
    if (['client-levels', 'client-levels.show'].includes(route.name) && !baseIsCollapsed.value) {
        emit('openMenu', 'companies')
    }

    const {data} = await companyApi.fetchClientLevels()
    levels.value = buildHierarchy(data);
}

function goToLevels() {
    emit('openMenu', 'companies')
    router.push({name: 'client-levels'})
}

function openClientLevel(id) {
    if (baseIsCollapsed.value) return
    emit('openMenu', 'companies')
    emit('openMenu', id)
}

onMounted(() => bus.$on('open-company', openClientLevel))

</script>
