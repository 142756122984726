<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="profile-info">
                <div class="profile-info__avatar">
                    <div class="profile-info__avatar-inner">
                        <input
                            id="uploadDialog"
                            style="display:none"
                            type="file"
                            class="form-control hidden"
                            @change="onImageChange"
                        >
                        <div class="profile-info__avatar-image-wrap">
                            <a
                                aria-label="profile image"
                                href="#"
                                class="profile-info__avatar-image"
                                @click="changePhoto"
                            >
                                <img
                                    class="profile-info__avatar-image-i"
                                    :src="form.photo_url"
                                    alt=""
                                >
                            </a>
                        </div>
                    </div>
                    <span
                        v-if="uploadError"
                        class="profile-info__avatar-upload-error"
                    >
                        {{ uploadError }}
                    </span>
                </div>
                <div class="profile-info__user-info">
                    <el-form
                        :model="form"
                        class="profile-info__form"
                    >
                        <div class="profile-info__form-section">
                            <div class="profile-info__form-section-title">
                                Main Information
                            </div>
                            <el-form-item
                                class="profile-info__form-section-item"
                                :error="formErrors.get('first_name')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.first_name"
                                        placeholder=" "
                                        class="el-input--default el-input--floating-label"
                                    >
                                        <template #suffix>
                                            First Name
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                            <el-form-item
                                class="profile-info__form-section-item"
                                :error="formErrors.get('last_name')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.last_name"
                                        placeholder=" "
                                        class="el-input--default el-input--floating-label"
                                    >
                                        <template #suffix>
                                            Last Name
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                            <el-form-item
                                class="profile-info__form-section-item"
                                :error="formErrors.get('email')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.email"
                                        placeholder=" "
                                        class="el-input--default el-input--floating-label"
                                    >
                                        <template #suffix>
                                            Email
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                            <el-form-item
                                class="profile-info__form-section-item"
                                :error="formErrors.get('phone_number')"
                            >
                                <label class="el-form-item__label--input-mask el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.phone_number"
                                        placeholder="(###)###-####"
                                        type="text"
                                        pattern="([0-9]{3})[0-9]{3}-[0-9]{4}"
                                        class="el-input--default el-input--input-mask el-input--floating-label"
                                        @input="phoneMaskFormatter"
                                    >
                                        <template #suffix>
                                            Phone Number
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="profile-info__form-section">
                            <div class="profile-info__form-section-title">
                                Secondary Information
                            </div>
                            <div class="profile-info__form-section-row">
                                <div class="profile-info__form-section-col">
                                    <el-select
                                        v-model="form.year"
                                        placeholder=" "
                                        name="year"
                                        class="el-select--default el-select--floating-label"
                                        :class="{'el-select--floating-label-value': form.year !== undefined }"
                                    >
                                        <el-option
                                            v-for="item in years"
                                            :id="`year-${item}`"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                        <template #prefix>
                                            Year
                                        </template>
                                    </el-select>
                                </div>
                                <div class="profile-info__form-section-col">
                                    <el-select
                                        v-model="form.month"
                                        placeholder=" "
                                        name="month"
                                        class="el-select--default el-select--floating-label"
                                        :class="{'el-select--floating-label-value': form.month !== undefined }"
                                    >
                                        <el-option
                                            v-for="item in months"
                                            :id="`month-${item}`"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                        <template #prefix>
                                            Month
                                        </template>
                                    </el-select>
                                </div>
                                <div class="profile-info__form-section-col">
                                    <el-select
                                        v-model="form.day"
                                        no-data-text="Select month first"
                                        placeholder=" "
                                        name="day"
                                        class="el-select--default el-select--floating-label"
                                        :class="{'el-select--floating-label-value': form.day !== undefined }"
                                    >
                                        <el-option
                                            v-for="item in days"
                                            :id="`day-${item}`"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        />
                                        <template #prefix>
                                            Day
                                        </template>
                                    </el-select>
                                </div>
                            </div>
                            <div class="profile-info__form-section-row">
                                <el-form-item
                                    :error="formErrors.get('sex')"
                                >
                                    <el-radio-group
                                        v-model="form.sex"
                                        class="el-radio-group-bordered radio-block profile-info__form-radio-block"
                                    >
                                        <el-radio label="male">
                                            Male
                                        </el-radio>
                                        <el-radio label="female">
                                            Female
                                        </el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <div v-if="showSuccessMessage">
                Your profile has been changed successfully.
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="main-content__buttons-wrap main-content__buttons-wrap--wrap">
                <div class="main-content__button-col">
                    <el-button
                        type="primary"
                        round
                        class="continue-button"
                        @click="onSubmit"
                    >
                        Save Profile
                    </el-button>
                </div>
                <div class="main-content__button-col">
                    <el-button
                        type=""
                        round
                        class="continue-button"
                        @click="onCancel"
                    >
                        Cancel
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
import {ElMessage} from 'element-plus'
import {Errors} from '~/includes/Errors'
import * as dayjs from 'dayjs'
import {useAuth} from '@websanova/vue-auth'

const router = useRouter()
const route = useRoute()
const auth = useAuth()
const user = auth.user()

const formErrors = ref(new Errors())

const form = ref({
    email: user.value.email,
    first_name: user.value.first_name,
    last_name: user.value.last_name,
    year: user.value.year,
    month: user.value.month,
    day: user.value.day,
    sex: user.value.sex,
    photo_url: user.value.avatar||'',
    phone_number: user.value.phone_number
})

const uploadError = ref('')
const image = ref(null)

const years = _.range(dayjs().year(), 1920)
const months = _.range(12, 0)
const days = computed(() => _.range(dayjs(form.value.year + '-' + form.value.month + '-1').daysInMonth(), 0))

onMounted(() => { bus.$emit('set-title', 'Profile Information') })

const showSuccessMessage = ref(false)

const onSubmit = () => {
    formErrors.value.record([])
    axios.patch( '/settings/profile', form.value).then(response => {
        // ElMessage.success('Profile information saved!')
        store.dispatch('auth/updateUser', response.data)
        showSuccessMessage.value = true
    }).catch(error => {
        console.log(error)
        formErrors.value.record(error.response.data.errors)
    })
}

function onCancel() {
    router.back()
}

function onImageChange(e) {
    let files = e.target.files || e.dataTransfer.files
    if (!files.length) {
        return
    }
    image.value = files[0]
    uploadImage()
}

function uploadImage() {
    let formData = new FormData()
    // let loader = this.$loading.show();
    // var self = this;
    formData.append('image', image.value)
    axios.post('image/upload',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    ).then(function (response) {
        form.value.photo_url = response && response.data && response.data.photo_url
        // loader.hide();
    }).catch(function (e) {
        if (e.response.status == '415') {
            uploadError.value = 'Upload file has unsupported type. \n\
                    Please upload file having types: jpg, gif, png, tif'
        }
        // loader.hide();
    })
}

function changePhoto() {
    uploadError.value = ''
    document.getElementById('uploadDialog').click()
}

function phoneMaskFormatter(value) {
    form.value.phone_number = form.value.phone_number.replace(/[\D]/g, '')
    if (form.value.phone_number.length > 5) {
        form.value.phone_number = form.value.phone_number.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)$2-$3').substring(0,13)
    } else if (form.value.phone_number.length > 3) {
        form.value.phone_number = form.value.phone_number.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)$2').substring(0,13)
    } else if (form.value.phone_number.length > 0) {
        form.value.phone_number = form.value.phone_number.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)').substring(0,13)
    } else {
        // form.value.phone_number = '(###)###-####'
    }
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.profile-info {
    $block-name: &;
    &__avatar {
        &-image {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            transition: 0.2s ease;
            &:hover {
                &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                visibility: hidden;
                opacity: 0;
                background-image: url(/images/ic_add_a_photo_black_24px.svg);
                background-size: 24px 24px;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: rgba(0, 0, 0, 0.5);
                transition: 0.2s visibility ease, 0.2s opacity ease;
            }
            &-i {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }
            &-wrap {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding-bottom: 15px;
                @media all and (min-width: $xs) {
                    padding-bottom: 30px;
                }
            }
        }
        &-upload-error {
            display: block;
            margin-bottom: 10px;
            font-size: 12px;
            text-align: center;
            color: $maroon;
        }
    }
    &__form {
        &-section {
            &-row {
                display: flex;
                margin: 0 -5px 15px;
                &:last-child {
                    margin: 0 -5px 0;
                }
                #{$block-name} {
                    &__form-section-item {
                        margin-bottom: 0;
                    }
                }
            }
            &-col {
                width: 33%;
                padding: 0 5px;
                @media all and (min-width: $xs) {
                    width: 25%;
                }
            }
            &-item {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-radio-block {
            display: inline-flex;
        }
    }
}
</style>
