import axios from 'axios'

export default {
    fetchCompanies() {
        return axios.get(window.config.ssURL + 'companies')
    },
    fetchDashboardProducts(client_level_id = '', data) {
        return axios.get(window.config.ssURL + 'retailers/dashboard-products/' + client_level_id, { params: data })
    },
}
