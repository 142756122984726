<template>
    <div class="el-main__content-top">
        <filter-top v-model="filters">
            <column-options v-model="columnIds" />
        </filter-top>
        <div class="el-main__content-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{name: 'dashboard'}">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{name: 'category'}"
                >
                    Products
                </el-breadcrumb-item>
                <template
                    v-for="category in hierarchy"
                    :key="category"
                >
                    <el-breadcrumb-item
                        v-if="'/category/' + category.path !== route.path"
                        :to="{path: '/category/' + category.path}"
                    >
                        {{ category.name }}
                    </el-breadcrumb-item>
                    <el-breadcrumb-item v-else>
                        {{ category.name }}
                    </el-breadcrumb-item>
                </template>
            </el-breadcrumb>
        </div>
    </div>
    <el-table
        v-loading="loading"
        :data="categories"
        border
    >
        <el-table-column
            fixed
            prop="name"
            label="Product Replaced"
            min-width="150"
        >
            <template #default="scope">
                <a
                    href="#"
                    class="cell__link"
                    @click.prevent="goToCategory(scope.row)"
                >
                    {{ scope.row.name }}
                </a>
            </template>
        </el-table-column>
        <template v-for="column in columns">
            <el-table-column
                v-if="column.show"
                :key="column._id"
                :prop="column._id"
                :label="column.name"
                min-width="110"
            />
        </template>
    </el-table>
</template>

<script setup>

import {useRoute, useRouter} from 'vue-router'
import {computed, onMounted, ref, watch} from 'vue'
import categoryApi from '../cateogryApi'
import bus from '~/includes/Event'
import FilterTop from './FilterTop'
import ColumnOptions from './ColumnOptions'

const route = useRoute()
const router = useRouter()

const loading = ref(true)

const filters = ref({
    dateRange: null,
    clientLevels: null,
})

const columnIds = ref([])
const columns = ref([])

watch(() => filters.value, val => {
    loadData()
},
{ deep: true }
)

watch(() => columnIds.value, val => {
    for(let i = 0; i < columns.value.length; i++) {
        columns.value[i].show = columnIds.value.includes(columns.value[i]._id)
    }
},
{ deep: true }
)

const categoryChain = route.path.split('/').filter(Boolean).slice(1)
const categories = ref(null)
const hierarchy = ref(null)

onMounted(() => loadColumnsData())

async function loadColumnsData() {
    loading.value = true
    columns.value = await categoryApi.fetchColumns()

    for(let i = 0; i < columns.value.length; i++) {
        columns.value[i].show = true
        if(columns.value[i].show) {
            columnIds.value.push(columns.value[i]._id)
        }
    }

    loading.value = false
}

async function loadData() {
    loading.value = true
    const {data} = await categoryApi.fetchStatisticsByParents(categoryChain, filters.value)
    categories.value = data.data
    hierarchy.value = data.hierarchy
    loading.value = false
}

function goToCategory(category) {
    if (category.level === 'segment') return
    bus.$emit('open-sub-category', route.path + '/' + category.slug)
    router.push({ path: route.path + '/' + category.slug })
}

</script>
