import CategoryIndex from './components/CategoryIndex'

export default [
    {
        path: 'category/:department?/:super_cat?/:cat?/:sub_cat?/:segment?',
        name: 'category',
        component: CategoryIndex,
        meta: {
            auth: 'retailer_categories.view'
        }
    },
]
