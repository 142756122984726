<template>
    <div class="profile-section">
        <profile-menu :list="userLinks" />
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import ProfileMenu from './ProfileMenu'
import bus from '~/includes/Event'
import {useAuth} from '@websanova/vue-auth'

const auth = useAuth()

onMounted(() => bus.$emit('set-title', 'Profile'))

const user = auth.user()

const fdmItemText = (user.is_primary ? 'Add ' : '') + 'Food Decision Maker'

const userLinks = ref([
    {
        path: 'profile.info',
        title: 'Personal Information',
        icon: '/images/svg-icons/user-gear-icon.svg'
    },
    {
        path: 'profile.reset-password',
        title: 'Reset Password',
        icon: '/images/svg-icons/key-icon.svg'
    },
])
</script>

<style lang="scss" scoped>
    .profile-section {
        padding-top: 20px;
    }
</style>
