import CoachIndex from './components/CoachIndex'

export default [
    {
        path: 'coaches',
        name: 'coaches',
        component: CoachIndex,
        meta: {
            auth: 'coaches.view'
        }
    },
    {
        path: 'coaches/:id',
        name: 'coaches.show',
        component: CoachIndex,
        meta: {
            auth: 'coaches.view'
        }
    },
]
