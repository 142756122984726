<template>
    <div class="el-main__content-top-filters">
        <div class="el-main__content-date-picker">
            <el-date-picker
                v-model="filters.dateRange"
                type="daterange"
                range-separator="–"
                start-placeholder="Start date"
                end-placeholder="End date"
                class="el-date-picker el-date-picker--no-grow"
                popper-class="el-popper--date-picker-period"
                :disabled-date="disabledDate"
                format="MM/DD/YYYY"
            />
        </div>
        <div
            v-if="clientLevels"
            class="el-main__content-filter"
        >
            <el-select
                v-model="filters.clientLevels"
                multiple
                collapse-tags
                collapse-tags-tooltip
                class="el-select--filter"
                placeholder="All Cl."
                popper-class="el-popper--companies"
                fit-input-width
            >
                <el-option
                    v-for="item in clientLevels"
                    :key="item._id"
                    :label="item.name"
                    :value="item._id"
                />
            </el-select>
        </div>
        <div
            class="el-main__content-filter"
            style="min-width: 240px;"
        >
            <el-select
                v-model="filters.store"
                filterable
                remote
                multiple
                collapse-tags
                collapse-tags-tooltip
                :remote-method="loadStores"
                :loading="loadingStores"
                class="el-select--filter el-select--filter-stores"
                placeholder="All Stores"
                popper-class="el-popper--stores"
                fit-input-width
            >
                <el-option
                    v-for="item in stores"
                    :key="item.uid"
                    :label="item.banner"
                    :value="item.uid"
                >
                    <span
                        class="el-select-dropdown__item-name"
                    >
                        {{ item.banner }}
                    </span>
                    <span
                        class="el-select-dropdown__item-address"
                    >
                        {{ item.address }}
                    </span>
                </el-option>
            </el-select>
        </div>
        <slot />
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import companyApi from '~/modules/company/companyApi'
import { useModelWrapper } from '~/includes/modelWrapper'
import cateogryApi from '~/modules/category/cateogryApi'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'modelValue')

const clientLevels = ref(null)

onMounted(() => {
    loadClientLevels()
    loadStores()
})

async function loadClientLevels() {
    const {data} = await companyApi.fetchClientLevels()
    clientLevels.value = data
}

const stores = ref(null)
const loadingStores = ref(false)

async function loadStores(query = '') {
    // if (query) {
    loadingStores.value = true
    const {data} = await cateogryApi.fetchStores(query)
    stores.value = data
    loadingStores.value = false
    // }
}


function disabledDate(time) {
    return time.getTime() > Date.now()
}
</script>
