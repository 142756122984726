import app from '~/app'

const dashboardPage = () => {
    const component = app.config.globalProperties.$auth.check('Customer') ? 'DashboardCustomer' : 'DashboardIndex'

    return import('./components/' + component).then(m => m.default || m)
}

export default [
    {
        path: 'dashboard',
        name: 'dashboard',
        component: dashboardPage,
    },
]
